@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@font-face { 
  font-family: 'Century Gothic'; 
  src: url('../fonts/century_gothic.eot'); 
  src: url('../fonts/century_gothic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/century_gothic.svg#Century Gothic') format('svg'), 
       url('../fonts/century_gothic.woff') format('woff'), 
       url('../fonts/century_gothic.ttf') format('truetype'); 
  font-weight: normal; 
  font-style: normal;
}

body {
  padding-top: 60px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: 1.5em;
  padding-bottom: 204px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f5f5f5;
}
.content {
  border-bottom: 1px solid #e7e7e7;
  -webkit-box-shadow: 0 0px 15px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0px 15px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0px 15px 0 rgba(0,0,0,0.1);
  width: 100%;
  //Only this position will make the Footer work:
  position: relative;
  z-index: 3;
  background-color: #fff;
}

.break {
	height: 80px;
	width: 100%;
}
.color {
	color: #00b2ef;
}
.theme {
  background-repeat : no-repeat;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;
  background-size: cover;
  background-position: center bottom;
  min-width: 100%;
  min-height:571px;
  font-size:35px;
  color:#ffffff;
  //text-shadow: 0 0 3px #2F3122;
}
.collection {
  background-repeat : no-repeat;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;
  background-size: cover;
  background-position: center;
  //min-width: 100%;
  color:#ffffff;
  margin: 6px 6px 0px 0px;
  padding: 0px;
  cursor:pointer;
}

.background {
  background-repeat : no-repeat;
  box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;
  background-size: cover;
  background-position: center;
  //GROW EFFECT
    //COMMENT to remove: zoom effect
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    margin:0px;
    position: relative;
  }
.background:hover {
  //GROW EFFECT
    //COMMENT to remove: zoom effect
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5,SizingMethod='auto expand')";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand');
    position: relative;
}
.shadow {
	-webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
}
a {
	//color:#00b2ef;
	color: #706F6F;
}
a:hover, a:focus {
	text-decoration: none;
	font-weight: bold;
	color:#00b2ef;
  cursor:pointer;
	//background-color: rgba(255, 255, 255, 0.15);
}

a:hover > .well {
	background-color: #00b2ef;
}
a:hover > .well > .background {
	//opacity: 0.5;
}

.cero {
	margin: 0px;
	padding: 0px;
	border: none;
}

.blueNav > li > a:hover, .blueNav > li > a:focus {
	background-color: #009de6;
}

.blue, .blue > div > a, .blue > div > div > div > p > a {
	background-color: #00b2ef;
	color: #fff;
}
/* BOTTONS */
.white {
	background-color: rgba(255, 255, 255, 0.15);
	border-color: #fff;
	color: #fff;
	transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
}
.white:hover, .white:focus {
	background-color: rgba(255, 255, 255, 0.4);
	border-color: #fff;
	color: #fff;
	//font-weight: bold;
}
.btblue {
	background-color: rgba(0, 178, 239, 0.1);
	border-color: #00b2ef;
	color: #00b2ef;
	transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
}
.btblue:hover, .btblue:focus {
	background-color: rgba(0, 178, 239, 1);
	border-color: #00b2ef;
	color: #fff;
}

/* Navbar Styling */
#fluid-nav {
  transition: opacity 750ms ease;
}
.navbar {    
  background-color: rgba(255, 255, 255, 0.98);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
  border: none;
  height: 60px;
}
.navbar-brand > img {
  display: inline-block;
}
.navbar-default .navbar-toggle {
  padding-top:10px;
  margin-top:15px;
  border: none;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border: none;
    background-color: rgba(255, 255, 255, 0.98);
	//-webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
	//box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
	//text-align: right;
}

/* Navbar links */
.navbar-nav > li > a {
  padding-top: 21px;
}
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active > a:hover, 
.navbar-default .navbar-nav > .active > a:focus {
  color: #00b2ef;
  background-color: transparent;
}
@media (min-width: 768px) {
  #fluid-nav .navbar-nav > li > a:before {
    content: "";
    padding-top: 18px;
    position: absolute;
    top: -3px;
    height: 0;
    left: 0;
    width: 100%;
    border-top: 3px solid #00b2ef;
    -webkit-transition: top 100ms;
    -moz-transition: top 100ms;
    transition: top 100ms;
  }
  #fluid-nav .navbar-nav > li > a:hover:before,
  #fluid-nav .navbar-nav > li > a:focus:before {
    top: 0;
  }
  #fluid-nav .navbar-nav > .active > a,
  #fluid-nav .navbar-nav > .active > a:hover,
  #fluid-nav .navbar-nav > .active > a:focus {
    padding-top: 18px;
    border-top: 3px solid #00b2ef;
    background-color: transparent;
    color: #00b2ef;
  }
  #fluid-nav .navbar-nav > .active > a:before,
  #fluid-nav .navbar-nav > .active > a:hover:before,
  #fluid-nav .navbar-nav > .active > a:focus:before {
    top: -3px;
  }
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #00b2ef;
}
/* BRINGS TOGGLE DROPDOW ERRORS */
/*.navbar-default .navbar-nav > li > a {
	color: #000 ;
}
.nav > li, .navbar-nav > li > a {
	height: 60px;
}*/
/* end navbar links */

/* FORMS */
.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  //padding: 10px;
}
.input-group-addon {
  border: none;
  padding: 4px 12px;
  background-color: #f5f5f5;
}
.form-control:focus {
  outline:0 !important;
  box-shadow:none;
  border-color:transparent;
  -webkit-appearance:none;
}
.fa-2x {
  margin-top: 3px;
  margin-bottom: -3px;
}
.nobtn {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  font-weight: normal;
  line-height: 1rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
.nobtn::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input, textarea {
  appearance:none;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  resize: none;
}

/* Footer Styling */
.footer {
  position: fixed;
  -webkit-backface-visibility: hidden;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 30px;
  background-color: #f5f5f5;
  min-height: 204px;
  line-height: 120%;
  color: #706F6F;
  font-size: 0.937em;
  text-align: left;
  z-index: 1;
  //position: relative;
}
footer h4 {
  font-size: 1em !important;
  font-weight: bold;
  color: #636262;
  margin-top: 30px;
  margin-bottom: 3px;
  text-align: left;
}
footer .copyright {
  margin-top: 30px;
  text-align: center;
}
footer h6 {
  color: #000;
  font-size: 0.687em;
  text-align: left;
}

footer .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 6px solid #fff;
  background-color: #fff;
}
footer .input-group-addon {
  border: none;
  padding: 4px 12px;
  background-color: #fff;
}
footer .form-control:focus {
  outline:0 !important;
  box-shadow:none;
  border-color:transparent;
  -webkit-appearance:none;
}
footer .fa-2x {
  margin-top: 3px;
  margin-bottom: -3px;
}
footer button {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  font-weight: normal;
  line-height: 1rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
footer button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.sitemap-links {
  color:#828282;
  text-align: left;
}
footer input {
  -webkit-appearance: none;
}
/* Footer alignment overrides */
#coaching-div {
  width: 22.33333%;
  padding: 0;
}
#blog-div {
  width: 18.33333%;
  padding: 0;
}
/* end footer alignment overrides*/
/* end footer styling */

/* INICIO */
@media screen and (min-width: 1750px) {
    .thome {
        min-height:900px;
    }
}
@media screen and (max-width: 700px) {
    .thome {
        min-height:300px;
    }
    .thome > div > p {
    	font-size:.6em;
    	padding-top: 20px;
    	color:#ffffff;
    }
}

/* EFFECTS */
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
//Not working from HERE
/*.pulse {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0;
    display: inline-block;
    margin: 0px;
    font-size: 20px;
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.0, 1.0); opacity: 0.0;}
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}*/

a:hover > .well > .nametag {
	background-color: rgba(255, 255, 255, 0.8);
	color:#828282;
	//This position will activate horizontal scrolling
	//position: absolute;
	margin-top:-40px;
	height: 40px;
	width: 100%;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

a > .well > .nametag {
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	background-color: rgba(255, 255, 255, 0.8);
	color: rgba(255, 255, 255, 0);
	//This position will activate horizontal scrolling on mobile
	//position: absolute;
	//This 'positoning' will solve the floating tag in safari
	position: relative;
	margin-top:-40px;
	height: 40px;
	width: 100%;
	-webkit-animation-name: fadeOutDown;
	animation-name: fadeOutDown;
}
.error {
    color: #D90800;
    font-style: italic;
    font-size: 12px;
    text-align: left;
    display: block;
    //visibility: hidden;
    //Or with opacity!
    //opacity: 0;
}